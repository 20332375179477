<template>
  <div class="w-100 p-2 mt-2">
    <page-header>
      <i class="fas fa-list"></i>
    </page-header>
    <page-card>
      <template v-slot:header>
        {{ $t('page.filters') }}
      </template>
      <template v-slot:content>
        <div class="sygni-grid">
          <div class="row">
            <div class="col-4">
              <filter-input
                  :filter-query="filterQuery"
                  @store="storeFilter"
                  :store-data="filters"
                  filter-operator="like"
                  filter-name="id"
                  :label="$t('filters.label.id')"
                  :placeholder="$t('filters.placeholder.id')">
              </filter-input>
            </div>
            <div class="col-4">
              <filter-input
                  :filter-query="filterQuery"
                  @store="storeFilter"
                  :store-data="filters"
                  filter-operator="like"
                  filter-name="email"
                  :label="$t('filters.label.email')"
                  :placeholder="$t('filters.placeholder.email')">
              </filter-input>
            </div>
            <div class="col-4">
              <filter-select
                  @store="storeFilter"
                  :store-data="filters"
                  :filter-query="filterQuery"
                  :options="filterOptions.status"
                  :multiple="false"
                  options-label="text"
                  options-track-by="value"
                  filter-name="status"
                  :label="$t('filters.label.status')"
                  :placeholder="$t('filters.placeholder.status')">
              </filter-select>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <b-button @click="resetFilters()" variant="secondary">{{ $t('filters.btn.clear') }}</b-button>
        <b-button @click="applyFilters()" variant="primary">{{ $t('filters.btn.apply') }}</b-button>
      </template>
    </page-card>


    <page-card>
      <template v-slot:header>
        {{ $t('page.title.kyc_list') }}
      </template>
      <template v-slot:content>
        <div class="sygni-grid">
          <div class="row mt-3">
            <div class="col-12">
              <grid-pagination :total-count="totalCount"
                               :per-page="perPage"
                               :current-page="currentPage"
                               @load-items="paginationReload"></grid-pagination>

              <div class="table-responsive">
                <b-table striped show-empty
                         :empty-text="$t('table.no_results')"
                         :busy="isBusy"
                         hover
                         head-variant="dark"
                         outlined
                         :sort-by.sync="orderColumn"
                         :sort-desc.sync="orderDirection"
                         :no-local-sorting="true"
                         :items="items"
                         :fields="fields">


                  <template #cell(goldAmount)="data">
                    <span style="float: right">{{ parseFloat(data.value).toFixed(0) }} g</span>
                  </template>
                  <template #cell(status)="data">
                    <b-badge v-if="data.item.status === 'not_verified'" variant="primary">{{ data.value }}</b-badge>
                    <b-badge v-if="data.item.status === 'verified'" variant="success">{{ data.value }}</b-badge>
                    <b-badge v-if="data.item.status === 'verification_in_progress'" variant="warning">{{ data.value }}</b-badge>
                    <b-badge v-if="data.item.status === 'verification_rejected'" variant="danger">{{ data.value }}</b-badge>
                  </template>

                  <template #cell(actions)="data">
                    <div class="table-action--cell">
                      <b-button v-if="['verified', 'verification_in_progress'].includes(data.item.status)" variant="primary" size="sm"
                                @click="showItemDocument(data.item.id, 'first_page_file')">
                        {{ $t('btn.firstPage')}}
                      </b-button>
                      <b-button v-if="['verified', 'verification_in_progress'].includes(data.item.status)" variant="primary" size="sm"
                                @click="showItemDocument(data.item.id, 'second_page_file')">
                        {{ $t('btn.secondPage')}}
                      </b-button>
                      <b-button v-if="data.item.status === 'verification_in_progress'" variant="success" size="sm"
                                @click="accept(data.item.id)">
                        {{ $t('btn.verify')}}
                      </b-button>
                      <b-button v-if="data.item.status === 'verification_in_progress'" variant="danger" size="sm"
                                @click="reject(data.item.id)">
                        {{ $t('btn.reject')}}
                      </b-button>
                    </div>
                  </template>

                  <template #table-busy>
                    <div class="text-center text-danger my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong>{{ $t('state.loading') }}</strong>
                    </div>
                  </template>

                </b-table>
              </div> <!-- #table-responsive-->

              <grid-pagination :total-count="totalCount"
                               :per-page="perPage"
                               :current-page="currentPage"
                               @load-items="paginationReload"></grid-pagination>

            </div>
          </div>
        </div>
      </template>
    </page-card>
    <b-modal
        ref="kyc-documents-modal"
        size="lg"
        title="Document"
    >
      <img :src="documentUrl" alt="Document" style="max-width:100%;"/>
      <template #modal-footer>

      </template>
    </b-modal>
  </div>
</template>

<script>

import GridPagination from "../../../components/grid/grid-pagination/GridPagination";
import PageHeader from "../../../components/page/PageHeader";
import PageCard from "../../../components/page/PageCard";
import {mapState} from "vuex";
import axios from "axios";
import FilterInput from "@/components/grid/filters/FilterInput.vue";
import FilterSelect from "../../../components/grid/filters/FilterSelect";

export default {
  props: ['apiKey'],
  components: {
    FilterInput,
    FilterSelect,
    PageCard,
    PageHeader,
    GridPagination,
  },
  data() {
    return {
      documentUrl: null,
      statuses: ['not_verified', 'verified', 'verification_rejected', 'verification_in_progress'],
      orderColumn: null,
      orderDirection: false,
      fields: [
        {key: 'email', label: this.$t('table.header.email')},
        {key: 'firstName', label: this.$t('table.header.firstName')},
        {key: 'lastName', label: this.$t('table.header.lastName')},
        {key: 'country', label: this.$t('table.header.country')},
        {key: 'birthdate', label: this.$t('table.header.birthdate'), formatter: (v) => {
            return (new Date(v)).toLocaleDateString();
          }
        },
        {key: 'documentType', label: this.$t('table.header.documentType')},
        {key: 'status', label: this.$t('table.header.status')},
        {key: 'actions', label: ''},
      ],
      selectOptions: {
        bulkActions: [
          {value: null, text: 'Akcje masowe'}
        ],
      },
      filterQuery: [],
      filterOptions: {
        status: [
          {value: 'verified', text: 'Verified'},
          {value: 'not_verified', text: 'Not Verified'},
          {value: 'verification_in_progress', text: 'Requested'},
          {value: 'verification_rejected', text: 'Rejected'},
        ],
      },
    };
  },
  mounted() {
    if (this.apiKey) {
      this.$store.commit('root/setApiKey', this.apiKey);
    }
    this.loadItems();
    document.addEventListener('keydown', this.bindEnter);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.bindEnter);
  },
  computed: {
    ...mapState({
      perPage: (state) => state.kycStore.perPage,
      currentPage: (state) => state.kycStore.currentPage,
      items: (state) => state.kycStore.items,
      isBusy: (state) => state.kycStore.isBusy,
      totalCount: (state) => state.kycStore.totalCount,
    }),
    filters: function () {
      return this.$store.getters["root/filter"]('kyc');
    },
  },
  methods: {
    storeFilter(data) {
      this.$store.commit('root/setFilter', {storeName: 'kyc', ...data})
    },
    createSortQuery() {
      let sorting;
      if (this.orderColumn) {
        if (this.orderDirection === true) {
          sorting = encodeURIComponent('+' + this.orderColumn);
        } else {
          sorting = encodeURIComponent('-' + this.orderColumn);
        }
        return '&sort[]=' + sorting;
      }
      return '';
    },
    bindEnter(e) {
      if (e.keyCode === 13) {
        this.applyFilters();
      }
    },
    setPerPage(page) {
      this.setPerPage = page;
    },
    loadItems() {
      this.$store.dispatch('kycStore/loadItems', {
        params: {
          offset: this.perPage * (this.currentPage - 1),
          limit: this.perPage,
        },
        filters: this.filterQuery,
        sort: this.createSortQuery()
      });
    },
          accept(id) {
            this.$store.dispatch('kycStore/accept', {
              id: id
            })
                .then(() => {
                  setTimeout(() => {
                    this.loadItems();
                  }, 1000)
                })
                .catch((error) => {
                  console.log('error');
                  console.log(error);

                });
          },
    reject(id) {
      this.$store.dispatch('kycStore/reject', {
        id: id
      })
          .then(() => {
            setTimeout(() => {
              this.loadItems();
            }, 1000)
          })
          .catch((error) => {
            console.log('error');
            console.log(error);

          });
    },
    showItemDocument(id, type) {
      let that = this;
      axios.get(process.env.VUE_APP_BASE_API + '/kyc/' + id + '/document/' + type, {responseType: "blob"})
          .then(function (response) {

            var reader = new window.FileReader();
            reader.readAsDataURL(response.data);
            reader.onload = function () {

              that.documentUrl = reader.result;
              that.$refs['kyc-documents-modal'].show();

            }
          });
    },
    changePage: function (currentPage) {
      this.$store.commit('kycStore/setCurrentPage', currentPage);
    },
    applyFilters: function () {
      this.changePage(1);
      this.loadItems();
    },
    resetFilters() {
      this.filterQuery = [];
      this.$root.$emit('resetFilters');
      this.initFilters();
      this.applyFilters();
    },
    initFilters() {
      this.$store.commit('root/initFilters', {
        storeName: 'kyc',
        filterNames: ['date', 'profit']
      });
    },
    paginationReload(data) {
      this.changePage(data.currentPage);
      this.$store.commit('kycStore/setPerPage', data.perPage);
      this.loadItems();
    },
  },
  watch: {
    orderColumn: function () {
      this.loadItems();
    },
    orderDirection: function () {
      this.loadItems();
    },
    filterQuery: function () {

    }
  }
}
</script>


